import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { gsap } from 'gsap';
import AnimatedLink from '../../common/AnimatedLink';
import getSlug from '../../utils/getSlug';
import HiddenBg from '../../assets/apartments/hidden-background.png';
import { CustomEase } from 'gsap/CustomEase';
gsap.registerPlugin(CustomEase);
const fullSizeAnimation = keyframes`
  from {
    r: 50px;
  }
  to {
    r: 100vw;
  }
`;

const smallSizeAnimation = keyframes`
  from {
    r: 100vw;
   
  }
  to {
    r: 50px;
  }
`;

const fullSizeAnimationMobile = keyframes`
  from {
    r: 50px;
  }
  to {
    r: 100vh;
  }
`;

const smallSizeAnimationMobile = keyframes`
  from {
    r: 100vh;
   
  }
  to {
    r: 50px;
  }
`;

const StyledModuleWrapper = styled.div`
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	color: ${({ theme }) => theme.colors.white};
	height: 100vh;
	overflow: hidden;
	position: relative;
	user-select: none;

	svg {
		bottom: 0;
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;

		.hidden {
			visibility: hidden;
		}
		.show-full-img {
			animation: ${fullSizeAnimation} 0.3s ease-in-out forwards;
		}

		.hide-full-img {
			animation: ${smallSizeAnimation} 0.3s ease-in-out;
		}

		.mobile {
			&.show-full-img {
				animation: ${fullSizeAnimationMobile} 0.3s ease-in-out forwards;
			}

			&.hide-full-img {
				animation: ${smallSizeAnimationMobile} 0.3s ease-in-out;
			}
		}
	}
`;

const fullSizeMaskAnimation = keyframes`
  from {
   opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const smallSizeMaskAnimation = keyframes`
  from {
    opacity: 0;
   
  }
  to {
    opacity: 1;
  }
`;

const StyledCursorMask = styled.div`
	border-radius: 50%;
	height: 100px;
	left: -50px;
	pointer-events: none;
	position: absolute;
	top: -50px;
	transform-origin: center;
	visibility: visible;
	width: 100px;
	&.hidden {
		visibility: hidden;
	}

	&.show-full-img {
		animation: ${fullSizeMaskAnimation} 0.1s ease-in-out forwards;
	}

	&.hide-full-img {
		animation: ${smallSizeMaskAnimation} 0.5s ease-in-out;
	}
`;

const StyledMaskInner = styled.div`
	//background: radial-gradient(circle, transparent 50%, red 70%);
	background: radial-gradient(circle, transparent 50%, rgb(31, 48, 45) 70%);
	border-radius: 50%;
	height: 100%;
	position: absolute;
	width: 100%;
`;

const StyledBackgroundColor = styled.div`
	background-color: ${({ theme }) => theme.colors.darkGreen};
	height: 100%;
	width: 100%;
`;

const StyledContentWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	left: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	width: 100%;
`;

const StyledHeader = styled.div`
	font-size: 33px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 45px;
	margin-bottom: 5px;
	position: relative;
	@media (min-width: 400px) {
		font-size: 36px;
	}
	@media (min-width: 768px) {
		font-size: 64px;
		line-height: 72px;
		margin-bottom: 13px;
	}
	@media (min-width: 1366px) {
		font-size: 100px;
		line-height: 120px;
		margin-bottom: 15px;
	}
`;

const StyledSubheader = styled.div`
	font-family: ${({ theme }) => theme.fontFamily.primaryItalic};
	font-size: 22px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 36px;
	margin-bottom: 35px;
	@media (min-width: 400px) {
		font-size: 24px;
	}
	@media (min-width: 768px) {
		font-size: 40px;
		line-height: 50px;
		margin-bottom: 50px;
	}
`;

const StyledButtonWrapper = styled.div`
	pointer-events: auto;
`;

const HomeHiddenBackgroundV3 = ({ data, activeLang }) => {
	const [isMobileDevice, setIsMobileDevice] = useState(true);

	const header = data?.homeHiddenBackgroundHeader || '';
	const subheader = data?.homeHiddenBackgroundSubheader || '';
	const btnText = data?.homeHiddenBackgroundButtonText || '';
	const btnLink = data?.homeHiddenBackgroundButtonLink?.slug || '';

	const [isMouseOnModel, setIsMouseOnModel] = useState(false);
	const [isMouseDown, setIsMouseDown] = useState(false);
	const [isRadiusLarge, setIsRadiusLarge] = useState(false);

	const moduleRef = useRef();
	const forMouseEnterOutRef = useRef();
	const cursorMaskRef = useRef();
	const cursorMaskInnerRef = useRef();
	const cursorRef = useRef();
	const isRadiusLargeRef = useRef(false);

	const getBrushPos = (xRef, yRef) => {
		const contextRect = moduleRef.current.getBoundingClientRect();

		return {
			x: Math.floor(((xRef - contextRect.left) / (contextRect.right - contextRect.left)) * contextRect.width),
			y: Math.floor(((yRef - contextRect.top) / (contextRect.bottom - contextRect.top)) * contextRect.height),
		};
	};

	const handleMouseEnter = () => {
		if (!isMouseOnModel) {
			setIsMouseOnModel(true);
		}
	};
	const handleMouseLeave = () => {
		if (isMouseOnModel) {
			setIsMouseOnModel(false);
		}
	};

	const handleMouseDown = (e) => {
		if (e.button === 0) {
			setIsMouseDown(true);
		}
	};

	const handleMouseUp = (e) => {
		if (e.button === 0) {
			setIsMouseDown(false);
		}
	};

	const handleTouchStart = (e) => {
		const cursorPosOnModule = getBrushPos(e.touches[0].clientX, e.touches[0].clientY);
		const cursorEl = cursorRef.current;
		const cursorMaskEl = cursorMaskRef.current;
		cursorEl.style.transform = `translate(${cursorPosOnModule.x}px, ${cursorPosOnModule.y}px)`;
		cursorMaskEl.style.transform = `translate(${cursorPosOnModule.x}px, ${cursorPosOnModule.y}px)`;
		setIsMouseOnModel(true);
		setIsMouseDown(true);
	};

	const handleTouchEnd = () => {
		setIsMouseOnModel(false);
		setIsMouseDown(false);
	};

	let prevEvent;
	let currentEvent;

	const cursorSizeAnimation = useRef(null);

	const cursorLargeAnimation = () => {
		if (cursorSizeAnimation.current) {
			cursorSizeAnimation.current.pause();
			cursorSizeAnimation.current.play();
		}
		isRadiusLargeRef.current = true;
		setIsRadiusLarge(true);
	};

	const cursorSmallAnimation = () => {
		if (cursorSizeAnimation.current) {
			cursorSizeAnimation.current.pause();
			cursorSizeAnimation.current.reverse();
		}
	};

	const calcBrushRadius = (e) => {
		currentEvent = e;
		if (prevEvent && currentEvent) {
			const movementX = Math.abs(currentEvent.screenX - prevEvent.screenX);
			const movementY = Math.abs(currentEvent.screenY - prevEvent.screenY);
			const movement = Math.sqrt(movementX * movementX + movementY * movementY);

			if (!isMouseDown && movement >= 15 && !isRadiusLargeRef.current) {
				cursorLargeAnimation();
			}
		}
		prevEvent = currentEvent;
	};

	const mouseStopped = () => {
		if (isRadiusLargeRef.current) {
			isRadiusLargeRef.current = false;
			setIsRadiusLarge(false);
		}
	};

	useEffect(() => {
		if (!isRadiusLarge) {
			cursorSmallAnimation();
		}
	}, [isRadiusLarge]);

	let timer;
	const handleMouseMove = (e) => {
		clearTimeout(timer);
		timer = setTimeout(mouseStopped, 150);
		const cursorPosOnModule = getBrushPos(e.clientX, e.clientY);
		const cursorEl = cursorRef.current;
		const cursorMaskEl = cursorMaskRef.current;
		// if (!isMouseDown) {
		calcBrushRadius(e);
		// }
		setTimeout(() => {
			// if (cursorEl && !isMouseDown) {
			if (cursorEl) {
				cursorEl.style.transform = `translate(${cursorPosOnModule.x}px, ${cursorPosOnModule.y}px)`;
				cursorMaskEl.style.transform = `translate(${cursorPosOnModule.x}px, ${cursorPosOnModule.y}px)`;
			}
		}, 150);
	};

	useEffect(() => {
		setIsMobileDevice(isMobile);
	}, []);

	useEffect(() => {
		document.documentElement.style.setProperty('--scale', 1);
		CustomEase.create('cubic', '0.16, 1, 0.3, 1');

		cursorSizeAnimation.current = gsap.timeline({
			repeat: 0,
			repeatDelay: 0,
			// defaults: { ease: 'Linear.easeNone' },
			defaults: { ease: 'cubic' },
		});
		// let duration = 2;
		const duration = 0.7;

		cursorSizeAnimation.current.add('start');
		cursorSizeAnimation.current.to(cursorRef.current, { duration, attr: { r: '200px' } }, 'start');
		cursorSizeAnimation.current.to(cursorMaskInnerRef.current, { duration, scale: 4 }, 'start');

		cursorSizeAnimation.current.pause();
	}, []);

	useEffect(() => {
		const moduleRefInstance = moduleRef.current;
		!isMobileDevice && moduleRefInstance.addEventListener('mousemove', handleMouseMove);

		return () => {
			!isMobileDevice && moduleRefInstance.removeEventListener('mousemove', handleMouseMove);
		};
	}, [isMouseDown, isMobileDevice]);
	// }, []);

	useEffect(() => {
		const forMouseEnterOutRefInstance = forMouseEnterOutRef.current;

		!isMobileDevice && forMouseEnterOutRefInstance.addEventListener('mouseenter', handleMouseEnter);
		!isMobileDevice && forMouseEnterOutRefInstance.addEventListener('mouseleave', handleMouseLeave);

		return () => {
			!isMobileDevice && forMouseEnterOutRefInstance.removeEventListener('mouseenter', handleMouseEnter);
			!isMobileDevice && forMouseEnterOutRefInstance.removeEventListener('mouseleave', handleMouseLeave);
		};
	}, [isMouseOnModel, isMobileDevice]);

	useEffect(() => {
		if (isMouseDown) {
			cursorRef.current.classList.remove('hide-full-img');
			cursorRef.current.classList.add('show-full-img');
			cursorMaskRef.current.classList.remove('hide-full-img');
			cursorMaskRef.current.classList.add('show-full-img');
		} else {
			cursorRef.current.classList.remove('show-full-img');
			cursorRef.current.classList.add('hide-full-img');
			cursorMaskRef.current.classList.remove('show-full-img');
			cursorMaskRef.current.classList.add('hide-full-img');
		}
	}, [isMouseDown]);

	return (
		<StyledModuleWrapper ref={moduleRef} id="scrollTo">
			<div
				ref={forMouseEnterOutRef}
				style={{ width: '100%', height: '100%' }}
				onMouseDown={(e) => (!isMobileDevice ? handleMouseDown(e) : null)}
				onMouseUp={(e) => (!isMobileDevice ? handleMouseUp(e) : null)}
				onTouchStart={(e) => (isMobileDevice ? handleTouchStart(e) : null)}
				onTouchEnd={(e) => (isMobileDevice ? handleTouchEnd() : null)}
			>
				<StyledBackgroundColor />
				<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
					<defs>
						<clipPath id="mask">
							<circle
								ref={cursorRef}
								className={`${isMobileDevice ? 'mobile' : ''} ${!isMouseOnModel ? 'hidden' : ''}`}
								id="mask-circle"
								r="50px"
								style={{
									width: '50px',
									height: '50px',
									position: 'absolute',
									top: 0,
									left: 0,
								}}
							/>
						</clipPath>
					</defs>
					<g clipPath="url(#mask)">
						<rect width="100%" height="100%" fill="rgb(31,48,45)" />
						{isMobileDevice ? (
							<image
								xmlnsXlink="http://www.w3.org/1999/xlink"
								xlinkHref={HiddenBg}
								width="1500px"
								height="100%"
								x="50%"
								transform="translate(-750,0)"
							/>
						) : (
							<image xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={HiddenBg} width="100%" height="100%" />
						)}
					</g>
					<circle
						id="circle-shadow"
						r="50px"
						style={{
							width: '50px',
							height: '50px',
							position: 'absolute',
							top: 0,
							left: 0,
							fill: 'transparent',
						}}
					/>
				</svg>
				<StyledCursorMask ref={cursorMaskRef} className={`${!isMouseOnModel ? 'hidden' : ''}`}>
					<StyledMaskInner ref={cursorMaskInnerRef} />
				</StyledCursorMask>
			</div>

			<StyledContentWrapper>
				<StyledHeader>{header}</StyledHeader>
				<StyledSubheader>{subheader}</StyledSubheader>
				{/* <StyledSubheader>{isMouseDown ? 'down' : 'up'}</StyledSubheader> */}
				<StyledButtonWrapper>
					<AnimatedLink to={`/${getSlug(btnLink, activeLang)}`} title={btnText} isDarkBg />
				</StyledButtonWrapper>
			</StyledContentWrapper>
		</StyledModuleWrapper>
	);
};

export default HomeHiddenBackgroundV3;
