import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import HomeVerticalSlider from './HomeVerticalSlider';
import HomeVerticalSliderMobile from './HomeVerticalSliderMobile';

const HomeVerticalSliderContainer = ({ data, activeLang }) => {
	const [isMobileDevice, setIsIsMobileDevice] = useState(true);

	useEffect(() => {
		setIsIsMobileDevice(isMobile);
	}, []);
	return !isMobileDevice ? (
		<HomeVerticalSlider data={data} activeLang={activeLang} />
	) : (
		<HomeVerticalSliderMobile data={data} activeLang={activeLang} />
	);
};

export default HomeVerticalSliderContainer;
