import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import uuid from 'react-uuid';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useSelector } from 'react-redux';
import { isMobileOnly, isSafari, isTablet } from 'react-device-detect';
import PageContainer from '../../common/PageContainer';
import GetHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import CommonSpaces from '../../assets/image/homePage/amenitiesSlider/sala_klubowa.webp';
import NohoSmart from '../../assets/image/homePage/verticalSlider/noho-smart.webp';
import Foodstories from '../../assets/image/homePage/verticalSlider/foodstories.webp';
import AnimatedLink from '../../common/AnimatedLink';
import getSlug from '../../utils/getSlug';

const StyledSliderContainer = styled.div`
	//padding-top: ${({ padding, isSafari, isTablet }) => (isSafari && isTablet ? '0' : `${padding}px`)};
	display: block;
	// margin-top: ${({ padding, isSafari, isTablet }) => (isSafari && isTablet ? `-${padding}px` : 0)};
	padding-top: ${({ padding }) => `${padding}px`};
	width: 100%;
	@media (min-width: 1281px) {
		display: none;
	}
`;

const StyledSingleSlideWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	transition: background-color 1s ease-in-out;
	width: 100%;
`;

const StyledTextPartWrapper = styled.div`
	background-color: transparent;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 40px 25px 50px;
	width: 100%;
	@media (min-width: 768px) {
		flex-direction: row;
		padding: 66px;
	}
`;

const StyledCounterWrapper = styled.div`
	display: flex;
	flex-direction: row;
	height: 67px;
	justify-content: space-between;
	margin-bottom: 23px;
	position: relative;
	width: 67px;
	&:after {
		background-color: ${({ theme }) => theme.colors.black};
		content: '';
		height: 1px;
		left: calc(50% - 23px / 2);
		position: absolute;
		top: calc(50% - 4px);
		transform: rotate(-45deg);
		width: 23px;
	}
	@media (min-width: 768px) {
		margin-right: 106px;
	}
`;

const StyledNumberWrapper = styled.div`
	color: ${({ theme }) => theme.colors.black};
	display: flex;
	flex-direction: column;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 20px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	height: 100%;
	justify-content: flex-end;
	line-height: 30px;
	width: 50%;
	&.current {
		justify-content: flex-start;
	}
`;

const StyledHeaderWrapper = styled.div`
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 36px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 45px;
	margin-bottom: 30px;
	@media (min-width: 768px) {
		font-size: 64px;
		line-height: 72px;
		margin-bottom: 40px;
		max-width: 300px;
	}
`;

const StyledDescriptionWrapper = styled.div`
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 16px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 26px;
	margin-bottom: 17px;
	max-width: 444px;
	@media (min-width: 768px) {
		margin-bottom: 37px;
	}
`;

const StyledImageWrapper = styled.img`
	height: 50vh;
	object-fit: cover;
	width: 100%;
`;

const StyledButtonWrapper = styled.div`
	pointer-events: auto;
`;

const HomeVerticalSliderMobile = ({ data, activeLang }) => {
	const buttonText = data?.homeVerticalSliderButtonText || '';
	const homeSliderSlides = data?.homeVerticalSliderSlides || [];
	const imagesArray = [CommonSpaces, NohoSmart, Foodstories];
	const isDropdownMenuOpen = useSelector((state) => state.isDropdownMenuOpen.isDropdownMenuOpen);
	const backupSingleSlideWidth = isMobileOnly ? 302 : isTablet ? 538 : 538;
	const slides = data?.homeSliderHoverEffectSlides || [];
	// const [offset, setOffset] = useState(backupSingleSlideWidth);
	const [offset, setOffset] = useState(0);
	// const xDistanceOffTheScreen = singleSlideWidth * slides.length - window.innerWidth + 70 - window.innerHeight * 0.7;
	// const xDistanceOffTheScreen = (singleSlideWidth * slides.length) / 1.5;
	// useEffect(() => {
	// 	gsap.registerPlugin(ScrollTrigger);
	// 	const textElements = document.querySelectorAll('.home-slide-text-wrapper-mobile');
	// 	textElements.forEach((element, key) => {
	// 		if (element.children) {
	// 			gsap.fromTo(
	// 				element.children,
	// 				{ y: '200', opacity: 0, duration: 0.2, ease: 'power4.easeOut' },
	// 				{
	// 					y: 0,
	// 					opacity: 1,
	// 					stagger: 0.15,
	// 					ease: 'power4.easeOut',
	// 					duration: 0.8,
	// 					scrollTrigger: {
	// 						id: `home-slider-mobile-${key}`,
	// 						trigger: `#home-image-slider-${key}`,
	// 						start: 'top bottom',
	// 						end: 'bottom 50%',
	// 						autoRefreshEvents: 'visibilitychange,DOMContentLoaded,load',
	// 						toggleActions: 'restart play complete reverse',
	// 						markers: false,
	// 					},
	// 				},
	// 			);
	// 		}
	// 	});
	//
	// 	return () => {
	// 		const singleSlide0ST = ScrollTrigger.getById('home-slider-mobile-0');
	// 		const singleSlide1ST = ScrollTrigger.getById('home-slider-mobile-1');
	// 		const singleSlide2ST = ScrollTrigger.getById('home-slider-mobile-2');
	// 		singleSlide0ST.kill();
	// 		singleSlide1ST.kill();
	// 		singleSlide2ST.kill();
	// 	};
	// }, [singleSlideWidth]);
	// useEffect(() => {
	// 	const slide = document.getElementById('amenities-slide-0').getBoundingClientRect();
	// 	setOffset((slide.width - 36) * 6 || backupSingleSlideWidth);
	// }, []);
	return (
		<PageContainer className="full-width">
			<StyledSliderContainer isSafari={isSafari} isTablet={isTablet} padding={offset} className="slides" id="slider-wrapper-mobile">
				{homeSliderSlides.map((item, key) => {
					const header = item?.homeVerticalSliderHeader || '';
					const description = item?.homeVerticalSliderDescription || '';
					const buttonLink = item?.homeVerticalSliderLink?.slug || '';
					return (
						<StyledSingleSlideWrapper className="home-vertical-slide-mobile" key={uuid()}>
							<StyledImageWrapper
								id={`home-image-slider-${key}`}
								src={imagesArray[key]}
								className="home-slide-image-wrapper"
							/>
							<StyledTextPartWrapper className="home-slide-text-wrapper-mobile">
								<StyledCounterWrapper>
									<StyledNumberWrapper className="current">0{key + 1}</StyledNumberWrapper>
									<StyledNumberWrapper>0{homeSliderSlides.length}</StyledNumberWrapper>
								</StyledCounterWrapper>
								<div style={{ width: '100%' }}>
									<StyledHeaderWrapper
										dangerouslySetInnerHTML={GetHtmlFromRedactorField(header)}
										className={`slide-header-${key}`}
									/>
									<StyledDescriptionWrapper
										dangerouslySetInnerHTML={GetHtmlFromRedactorField(description)}
										className={`slide-description-${key}`}
									/>
									<StyledButtonWrapper>
										<AnimatedLink to={`/${getSlug(buttonLink, activeLang)}`} title={buttonText} />
									</StyledButtonWrapper>
								</div>
							</StyledTextPartWrapper>
						</StyledSingleSlideWrapper>
					);
				})}
			</StyledSliderContainer>
		</PageContainer>
	);
};

export default HomeVerticalSliderMobile;
