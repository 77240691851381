import React, { useCallback, useEffect, useRef, useState } from 'react';
import Coworking from '../../assets/image/homePage/amenitiesSlider/coworking_z_czytelnia.webp';
import ClubRoom from '../../assets/image/homePage/amenitiesSlider/sala_klubowa.webp';
import SaunaWellness from '../../assets/image/homePage/amenitiesSlider/sauna_i_wellness.webp';
import CigarRoom from '../../assets/image/homePage/amenitiesSlider/pokoj_cygar.webp';
import { Flip } from 'gsap/Flip';
import CinemaRoom from '../../assets/image/homePage/amenitiesSlider/sala_kinowa.webp';
import Fitness from '../../assets/image/homePage/amenitiesSlider/fitness.webp';
import MassageRoom from '../../assets/image/homePage/amenitiesSlider/sala_masazu.webp';
import PackageRoomAndLobby from '../../assets/image/homePage/amenitiesSlider/pokoj_paczek_i_lobby.webp';
import useEmblaCarousel from 'embla-carousel-react';
import uuid from 'react-uuid';
import styled from 'styled-components';
import ArrowBlack from '../../assets/globals/arrow-black.svg';
import gsap from 'gsap';
import ArrowButton from '../../common/ArrowButton';

const StyledModuleContainer = styled.div`
	background-color: var(--amenities-bg-color);
	display: block;
	height: auto;
	overflow: hidden;
	position: relative;
	z-index: 10;
	transition: background-color 0.5s cubic-bezier(0.31, 0.13, 0.11, 1);
	width: 100%;
	* {
		box-sizing: border-box;
	}

	.progress-bar-container {
		width: 100%;
		height: 4px;
		background-color: #f3f3f3;
		overflow: hidden;
	}

	.progress-bar {
		width: 100%;
		height: 100%;
		background-color: #007bff;
		transform-origin: 0 0;
		transition: transform 0.3s;
	}
`;

const StyledEmblaCarouselWrapper = styled.div`
	overflow: hidden;
	height: 100%;
	margin-top: 40px;
	z-index: 9;
	@media (min-width: 768px) {
		margin-top: 0;
	}
`;

const StyledEmblaSliderWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-wrap: nowrap;
	position: relative;
	@media (max-height: 700px) and (min-width: 767px) {
		align-items: baseline;
	}
	transition: all 0.3s ease-in-out;
	width: ${({ items }) => `calc(${items} * (270px + 16px + 16px))`};
	@media (min-width: 768px) {
		width: ${({ items }) => `calc(${items} * (444px + 47px + 47px))`};
		padding-top: 74px;
		align-items: center;
	}
	@media (min-width: 1280px) {
		padding-top: 0;
	}

	.embla {
		overflow: hidden;
	}
	.embla__container {
		display: flex;
		align-items: center;
	}
`;

const StyledScaledImageWrapper = styled.div`
	height: 200px;
	@media (min-height: 650px) {
		height: calc((var(--inner-height) * 0.5 - 30px));
	}
	left: 16px;
	object-fit: cover;
	position: absolute;
	top: 16px;
	width: calc(100% - 32px);
	z-index: -1;
	@media (min-width: 1024px) {
		width: 50%;
		height: calc(100% - 32px);
		left: unset;
		top: 16px;
		right: 16px;
	}
`;

const AmenitiesWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-wrap: nowrap;
	overflow: hidden;
	@media (max-height: 700px) and (min-width: 767px) {
		align-items: baseline;
	}
	width: ${({ items }) => `calc(${items} * (270px + 16px + 16px))`};
	@media (min-width: 768px) {
		width: ${({ items }) => `calc(${items} * (444px + 47px + 47px))`};
		align-items: center;
	}
	@media (min-width: 1280px) {
		//height: 100vh;
		padding-top: 155px;
		align-items: flex-start;
		height: 80vh;
		max-height: 800px;
	}
`;

const StyledSingleSlide = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-start;
	margin-top: 50px;
	max-height: 414px;
	opacity: 1;
	padding: 0 16px;
	position: relative;
	text-align: left;
	transition: opacity 0.4s ease-in-out 0s;
	width: 302px;
	&.is-current {
		z-index: -1;
	}
	&.is-not-current {
		opacity: 0;
		transition: opacity 0.4s ease-in-out 0s;
		z-index: -1;
	}
	img {
		display: block;
		height: 360px;
		max-width: 100%;
		width: 270px;
	}
	@media (min-width: 768px) {
		padding: 0 47px 66px;
		//max-height: 492px;
		max-height: 566px;
		//width: 538px;
		width: 480px;
		margin-top: 0;
		&:nth-child(odd) {
			justify-content: flex-end;
		}
		&:nth-child(even) {
			justify-content: flex-start;
		}
		img {
			//max-width: 100%;
			display: block;
			width: 375px;
			height: 500px;
		}
	}
	@media (min-width: 1024px) {
		width: 538px;
		padding: 0 47px 66px;
		margin-top: 0;
		img {
			max-width: 100%;
			display: block;
			width: 444px;
			height: 300px;
		}
	}
`;

const StyledSlideContent = styled.div`
	height: 320px;
	position: relative;
	@media (min-height: 650px) and (max-width: 1024px) {
		//height: 360px;
		height: 414px;
	}
	@media (min-width: 768px) {
		//height: 300px;
		height: 500px;
	}
	@media (min-width: 1024px) {
		height: 300px;
	}
	&:nth-child(odd) {
		justify-content: flex-end;
	}
	&:nth-child(even) {
		justify-content: flex-start;
	}
`;

const StyledSlideInnerWrapper = styled.div`
	background-color: transparent;
	height: 280px;
	overflow: hidden;
	@media (min-height: 650px) {
		height: 360px;
	}
	@media (min-width: 768px) {
		height: 500px;
	}
	img {
		transform: scale(1);
		transition: transform 0.4s ease-in-out;
	}
	&:hover {
		img {
			transform: scale(1.1);
			transition: transform 0.4s ease-in-out;
		}
	}
	&.no-zooming {
		img {
			transform: scale(1);
			transition: transform 0s ease-in-out;
		}
		&:hover {
			img {
				transform: scale(1);
				transition: transform 0.4s ease-in-out;
			}
		}
	}
`;

const StyledImage = styled.img`
	background-position: center;
	height: 100%;
	object-fit: cover;
	opacity: 1;
	transition: all 0.3s ease-in-out;
	width: 100%;
	&.is-current {
		transition: all 2s ease-in-out;
		&:hover {
			cursor: default;
		}
	}
	&.is-not-current {
		opacity: 0;
	}
`;

const StyledTitleAndNumberWrapper = styled.div`
	bottom: 0;
	display: flex;
	flex-direction: row;
	height: 30px;
	justify-content: space-between;
	align-items: center;
	left: 0;
	opacity: 1;
	position: absolute;
	transition: opacity 0.3s ease-in-out;
	width: 270px;
	@media (min-width: 768px) {
		width: 375px;
		bottom: -50px;
	}
	@media (min-width: 1024px) {
		width: 444px;
		bottom: -50px;
	}
	p {
		color: ${({ theme }) => theme.colors.black};
		font-family: ${({ theme }) => theme.fontFamily.primary};
		font-size: 20px;
		font-weight: ${({ theme }) => theme.fontWeight.light};
		line-height: 28px;
		@media (min-width: 768px) {
			line-height: 45px;
		}
		@media (min-width: 1024px) {
			font-weight: ${({ theme }) => theme.fontWeight.regular};
		}
		&.number {
			color: ${({ theme }) => theme.colors.black};
			font-family: ${({ theme }) => theme.fontFamily.secondary};
			font-weight: ${({ theme }) => theme.fontWeight.regular};
			line-height: 30px;
		}
	}
`;

const StyledProgressBarAndArrowsWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
	position: relative;
	@media (min-width: 1280px) {
		justify-content: center;
		padding: 50px 0;
	}
`;

const StyledArrowWrapper = styled.div`
	transform: rotate(0deg);
	padding: 30px;
	&.left {
		transform: rotate(180deg);
	}
	//@media (min-width: 1280px) {
	//	display: none;
	//}
`;

const StyledProgressBar = styled.div`
	background-color: rgba(28, 28, 28, 0.15);
	border-radius: 5px;
	height: 2px;
	position: relative;
	width: 118px;
`;

const StyledCurrentProgress = styled.div`
	--progress: 0;
	background-color: ${({ theme }) => theme.colors.black};
	border-radius: 5px;
	height: 2px;
	left: 0;
	position: absolute;
	top: 0;
	width: 118px;
	transform-origin: left center;
	transform: ${({ progress }) => `scaleX(${progress})`};
`;

const StyledSingleSlideDetailsWrapper = styled.div`
	height: calc((var(--inner-height) - 250px));
	top: 240px;
	@media (min-height: 650px) {
		height: calc((var(--inner-height) * 0.5));
		top: calc((var(--inner-height) * 0.5) + 20px);
	}
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	left: 0;
	opacity: 0;
	padding-bottom: 46px;
	padding-left: 23px;
	padding-right: 10px;
	//position: fixed;
	position: absolute;
	transition: opacity 0.3s ease-in-out 2.2s;
	width: 100vw;
	z-index: -1;
	&.visible {
		opacity: 1;
		z-index: 11;
		transition: opacity 0.6s ease-in-out 0.2s;
	}
	@media (min-width: 1024px) {
		padding: 114px 0 0 90px;
		top: 0;
		left: 0;
		bottom: unset;
		width: 50vw;
		height: 100vh;
	}
`;

const StyledDetailsTextWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-start;
	padding-top: 0;
	position: relative;
	transition: all 0.3s ease-in-out;
	width: 100%;
	@media (min-width: 768px) {
		height: 100%;
		flex-direction: row;
		padding-top: 0;
	}
	@media (min-width: 1024px) {
		flex-direction: column;
	}
	@media (min-width: 1024px) {
		width: calc(100% - 90px - 105px);
		height: 100%;
		transition: all 0.3s ease-in-out;
		justify-content: flex-start;
		align-items: flex-start;
	}
`;

const StyledHeaderWrapper = styled.div`
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 44px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 54px;
	margin-bottom: 20px;
	@media (min-width: 768px) {
		max-width: 285px;
		font-size: 80px;
		line-height: 90px;
		margin-bottom: 32px;
	}
	@media (min-width: 1024px) {
		font-size: 100px;
		margin-bottom: 40px;
		line-height: 120px;
		max-width: none;
	}
`;

const StyledDescriptionWrapper = styled.div`
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 16px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 28px;
	max-width: 476px;
	@media (min-width: 768px) {
		max-width: 420px;
	}
	@media (min-width: 1024px) {
		max-width: none;
	}
`;

const StyledButtonWrapper = styled.div`
	margin-bottom: 12px;
	@media (min-width: 768px) {
		margin: 12px 86px 0 64px;
	}
	@media (min-width: 1024px) {
		margin: 0 0 24px;
	}
`;

const StyledFullscreenBlend = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: var(--amenities-bg-color);
	z-index: -1;
	transition: all 0.5s ease-out 0.5s;
	transform: scaleX(0);
	transform-origin: left;
	@media (min-width: 1280px) {
		transition: all 0.3s ease-out 0.4s;
		transform-origin: left;
		transform: scaleX(0);
	}
	&.visible {
		opacity: 1;
		transition: all 0.3s ease-in-out;
		transform: scaleX(1);
		@media (min-width: 1280px) {
			transform: scaleX(1);
		}
		//transform: scaleY(1);
	}
`;

const AMENITIES_BG_COLORS = ['#EDEBEA', '#E3DED6', '#CEBFB5', '#C1BEB5', '#ABAFB7', '#ADA9A5', '#B5A195', '#BBC0C4', 'rgba(49,49,49,0.2)'];
const IMAGES_ARRAY = [Coworking, ClubRoom, SaunaWellness, CigarRoom, CinemaRoom, Fitness, MassageRoom, PackageRoomAndLobby];
gsap.registerPlugin(Flip);
const CarouselSlides = React.memo(({ emblaRef, slides, containerRef, imageDestRef, progressRef }) => {
	const imagesRefs = slides.map(() => React.createRef());
	const contentsRefs = slides.map(() => React.createRef());
	const sliderBackButtonRef = useRef();
	const sliderHeaderRef = useRef();
	const sliderDescriptionRef = useRef();
	const slidesDetailsRef = useRef();
	const titlesRefs = slides.map(() => React.createRef());
	const setCurrentBackground = (index) => {
		const color = AMENITIES_BG_COLORS[index] || AMENITIES_BG_COLORS[AMENITIES_BG_COLORS.length - 1];
		document.documentElement.style.setProperty('--amenities-bg-color', color);
	};

	const handleCurrentIndex = (key) => {
		containerRef.current.setAttribute('currentIndex', key);
	};

	const handleCurrentHeader = (key) => {
		const headerElement = sliderHeaderRef.current;
		const headerText = slides[key].homeSliderHoverEffectTitle || '';
		headerElement.innerText = headerText;
	};

	const handleCurrentDescription = (key) => {
		const descriptionElement = sliderDescriptionRef.current;
		const descriptionText = slides[key].homeSliderHoverEffectDescription || '';
		descriptionElement.innerText = descriptionText;
	};

	const handleCloseSlide = () => {
		setTimeout(() => {
			const slidesElements = document.getElementsByClassName('slide');
			const slidesArray = Array.from(slidesElements);
			slidesArray.forEach((item) => {
				item.classList.remove('is-current');
				item.classList.remove('is-not-current');
			});
			imageDestRef.current.style.position = 'absolute';
			slidesDetailsRef.current.style.position = 'absolute';
			const fixedBlend = blendRef.current;
			fixedBlend.classList.remove('visible');
			blendRef.current.setAttribute('imageZoomed', false);
			document.body.style.overflow = 'auto';
			const scrollY = document.body.style.top;
			document.body.style.position = '';
			document.body.style.top = '';
			window.scrollTo(0, parseInt(scrollY || '0') * -1);
		}, 900);
	};

	const handleImageClick = (key, e) => {
		e.preventDefault();
		document.body.style.overflow = 'hidden';
		const offsetTop = window.scrollY;
		document.body.style.position = 'fixed';
		document.body.style.top = `-${offsetTop}px`;
		const fixedBlend = blendRef.current;
		fixedBlend.classList.add('visible');
		blendRef.current.setAttribute('imageZoomed', true);
		const currentSlideSelector = `slide-${key}`;
		const currentImage = imagesRefs[key].current;
		const currentContentElement = contentsRefs[key].current;
		// const menuIconElement = document.getElementById('menu-icon-wrapper');
		// menuIconElement.style.display = 'none';
		if (!currentImage.classList.contains('is-current')) {
			currentContentElement.classList.add('no-zooming');
			currentImage.classList.add('is-current');
			progressRef.current.style.opacity = '0';
			slidesDetailsRef.current.style.zIndex = '11';
			document.getElementsByClassName(currentSlideSelector)[0].style.zIndex = -1;
			const slidesElements = document.getElementsByClassName('slide');
			const slidesArray = Array.from(slidesElements);
			const restOfSlides = slidesArray.filter((item) => !item.classList.contains(currentSlideSelector));
			restOfSlides.forEach((item) => {
				item.classList.add('is-not-current');
				item.classList.remove('is-current');
				item.style.zIndex = '-1';
			});
			handleCurrentIndex(key);
			handleCurrentHeader(key);
			handleCurrentDescription(key);
			imageDestRef.current.style.position = 'fixed';
			slidesDetailsRef.current.style.position = 'fixed';
			imageDestRef.current.style.zIndex = '0';
			titlesRefs[key].current.style.opacity = '0';
			const button = sliderBackButtonRef.current;
			const header = sliderHeaderRef.current;
			const description = sliderDescriptionRef.current;
			const elements = [button, header, description];

			gsap.set([button, header, description], { autoAlpha: 0 });
			const timeline = gsap.timeline({ defaults: { ease: 'power3.inOut' } });
			timeline.fromTo(
				elements,
				{
					autoAlpha: 0,
					ease: 'power4.easeInOut',
					y: 200,
					duration: 0.4,
				},
				{
					autoAlpha: 1,
					delay: 0.2,
					stagger: 0.1,
					ease: 'power4.easeInOut',
					y: 0,
					duration: 1,
				},
			);

			Flip.fit(contentsRefs[key].current, imageDestRef.current, {
				scale: true,
				duration: 0.7,
				delay: 0.2,
				ease: 'power3.inOut',
				onStart: () => {
					setTimeout(() => {
						imageDestRef.current.appendChild(imagesRefs[key].current);
					}, 410);
				},
			});
			const detailsElement = slidesDetailsRef.current;
			detailsElement.classList.add('visible');
		}
	};

	const getCurrentIndexFromAttribute = () => {
		return containerRef.current.getAttribute('currentIndex');
	};

	const handleCloseDetailsClick = () => {
		const currentIndex = getCurrentIndexFromAttribute();
		const currentSlideSelector = `slide-${currentIndex}`;
		const button = sliderBackButtonRef.current;
		const header = sliderHeaderRef.current;
		const description = sliderDescriptionRef.current;
		const elements = [description, header, button];
		gsap.set([description, header, button], { autoAlpha: 1 });
		const timeline = gsap.timeline({ defaults: { ease: 'power3.inOut' } });
		timeline.fromTo(
			elements,
			{
				autoAlpha: 1,
				ease: 'power4.easeInOut',
				y: 0,
				duration: 1,
			},
			{
				autoAlpha: 0,
				delay: 0,
				stagger: 0.1,
				ease: 'power4.easeInOut',
				y: 200,
				duration: 0.4,
			},
		);

		Flip.fit(imageDestRef.current, imagesRefs[currentIndex].current, {
			scale: true,
			duration: 0.01,
			ease: 'power3.inOut',
			onStart: () => {
				setTimeout(() => {
					contentsRefs[currentIndex].current.appendChild(imagesRefs[currentIndex].current);
				}, 750);
				setTimeout(() => {
					contentsRefs[currentIndex].current.removeAttribute('style');
					imageDestRef.current.removeAttribute('style');
					imageDestRef.current.style.zIndex = '-1';
				}, 1200);
			},
			onComplete: () => {
				const currentContentElement = contentsRefs[currentIndex].current;
				const currentImageElement = imagesRefs[currentIndex].current;
				currentContentElement.classList.remove('no-zooming');
				currentImageElement.classList.remove('is-current');
				contentsRefs[currentIndex].current.style.transition = 'transform 0.6s ease-in-out 0.6s';
				contentsRefs[currentIndex].current.style.transform = 'translate(0px,0px) scale(1)';
				slidesDetailsRef.current.style.zIndex = '-1';
				document.getElementsByClassName(currentSlideSelector)[0].style.zIndex = 'auto';
				const slidesElements = document.getElementsByClassName('slide');
				const slidesArray = Array.from(slidesElements);
				slidesArray.forEach((item) => {
					item.style.zIndex = 'auto';
				});
				contentsRefs[currentIndex].current.style.height = '300px';
				contentsRefs[currentIndex].current.style.width = '444px';
				titlesRefs[currentIndex].current.style.opacity = '1';
				titlesRefs[currentIndex].current.style.transition = 'opacity 0.3s ease-in-out 0.8s';
				progressRef.current.style.opacity = '1';
				progressRef.current.style.transition = 'opacity 0.3s ease-in-out 0.8s';
				const detailsElement = slidesDetailsRef.current;
				// document.getElementById('menu-icon-wrapper').style.display = 'flex';
				detailsElement.classList.remove('visible');
			},
		});
		handleCloseSlide();
	};
	const blendRef = useRef();
	return (
		<>
			<StyledFullscreenBlend ref={blendRef} />
			<StyledSingleSlideDetailsWrapper data-cursor="default-cursor" ref={slidesDetailsRef}>
				<StyledDetailsTextWrapper>
					<StyledButtonWrapper ref={sliderBackButtonRef}>
						<ArrowButton
							arrowLeft
							onClick={() => {
								handleCloseDetailsClick();
							}}
						/>
					</StyledButtonWrapper>
					<div style={{ width: '100%' }}>
						<StyledHeaderWrapper ref={sliderHeaderRef} />
						<StyledDescriptionWrapper ref={sliderDescriptionRef} />
					</div>
				</StyledDetailsTextWrapper>
			</StyledSingleSlideDetailsWrapper>
			<StyledEmblaCarouselWrapper className="embla" ref={emblaRef}>
				<AmenitiesWrapper className="embla__container">
					{slides.map((item, key) => {
						const title = item?.homeSliderHoverEffectTitle || '';
						const slideNumber = key + 1;
						return (
							<StyledSingleSlide id={`amenities-slide-${key}`} key={uuid()} className={`slide slide-${key}`}>
								<StyledSlideContent>
									<StyledSlideInnerWrapper ref={contentsRefs[key]}>
										<StyledImage
											data-cursor="blend-mode-circle-amenities2"
											ref={imagesRefs[key]}
											id={`image-${key}`}
											className={`image image-${key}`}
											onMouseOver={() => setCurrentBackground(key)}
											onClick={(e) => handleImageClick(key, e)}
											src={IMAGES_ARRAY[key]}
										/>
									</StyledSlideInnerWrapper>
									<StyledTitleAndNumberWrapper ref={titlesRefs[key]}>
										<p>{title}</p>
										<p className="number">0{slideNumber}</p>
									</StyledTitleAndNumberWrapper>
								</StyledSlideContent>
							</StyledSingleSlide>
						);
					})}
				</AmenitiesWrapper>
			</StyledEmblaCarouselWrapper>
		</>
	);
});

const EmblaSlider = ({ data }) => {
	const header = data?.homeSliderHoverEffectHeader || '';
	const slides = data?.homeSliderHoverEffectSlides || [];
	const imageDestinationRef = useRef();
	const progressBarRef = useRef();
	const amenitiesContainerRef = useRef();

	const [emblaRef, emblaApi] = useEmblaCarousel({
		align: 'start',
		dragFree: true,
		// active: true,
	});
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		if (!emblaApi) return;
		onScroll();
		emblaApi.on('scroll', onScroll);
		// return () => removeOnSelectListener;
	}, [emblaApi]);

	const scrollPrev = useCallback(() => {
		if (emblaApi) emblaApi.scrollPrev();
	}, [emblaApi]);

	const scrollNext = useCallback(() => {
		if (emblaApi) emblaApi.scrollNext();
	}, [emblaApi]);

	const onScroll = useCallback((emblaApi, eventName) => {
		if (!emblaApi) return;
		const progress = Math.max(0, Math.min(1, emblaApi.scrollProgress()));
		setProgress(progress);
	}, []);

	const removeOnSelectListener = useCallback(() => {
		if (emblaApi) emblaApi.off('scroll', onScroll);
		// }, [emblaApi, onScroll]);
	}, [emblaApi]);

	return (
		<StyledModuleContainer currentIndex ref={amenitiesContainerRef} data-cursor="blend-mode-circle-amenities">
			<StyledEmblaSliderWrapper items={slides.length}>
				<StyledScaledImageWrapper ref={imageDestinationRef} />
				<CarouselSlides
					imageDestRef={imageDestinationRef}
					containerRef={amenitiesContainerRef}
					progressRef={progressBarRef}
					emblaRef={emblaRef}
					slides={slides}
				/>
			</StyledEmblaSliderWrapper>
			<StyledProgressBarAndArrowsWrapper data-cursor="default-cursor" ref={progressBarRef}>
				<StyledArrowWrapper className="left" onClick={scrollPrev}>
					<img alt="back-slide" src={ArrowBlack} />
				</StyledArrowWrapper>
				<StyledProgressBar>
					<StyledCurrentProgress progress={progress} className="progress" />
				</StyledProgressBar>
				<StyledArrowWrapper onClick={scrollNext}>
					<img alt="next-slide" src={ArrowBlack} />
				</StyledArrowWrapper>
			</StyledProgressBarAndArrowsWrapper>
		</StyledModuleContainer>
	);
};

export default EmblaSlider;
